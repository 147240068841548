export const locale = {
    lang: 'en',
    data: {
        ACTIVITIES_TOOLTIP: {
            TITLES: {
                LIKE: 'I like this',
                DISLIKE: 'Not for me',
                SHOWN: 'Visited this property',
            }
        }
    }
}; 
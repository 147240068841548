import { createAction, props } from '@ngrx/store';

import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { MapShapesSearchOptions } from '@listings/models/search/map-shapes-search-options';
import { SearchRunMode } from '@search/enums/search-run-mode.enum';

export const changeSearchOptions = createAction('[Search] Change Search Options', props<{ searchOptions: Partial<ListingSearchOptions> }>());

export const setSearchOptions = createAction('[Search] Set Search Options', props<{ searchOptions: ListingSearchOptions }>());

export const addCustomerSearchActivities = createAction(
    '[Search] Add Customer Search Actions',
    props<{ categoryId: number, fields: { fieldName: string, fieldValue: string, optionName?: string }[] }>()
);

export const clearSearchCriteria = createAction('[Search] Clear Search Criteria', props<{ categoryId?: number }>());

export const setMapShapesSearchOptions = createAction('[Search] Set Map Shapes Search Options', props<{ mapOptions: MapShapesSearchOptions }>());

export const setSelectedMapNeighborhoods = createAction('[Search] Set Selected Neighborhoods', props<{ selectedNeighborhoodIds: number[] }>());

export const setSearchRunMode = createAction('[Search] Set Search Run Mode', props<{ mode: SearchRunMode }>());

export const search = createAction('[Search] Search', props<{ searchOptions: ListingSearchOptions | null, previousSearchOptions?: ListingSearchOptions, shouldSetLoading?: boolean }>());

<ng-container *ngIf="appearanceType === 'icon'">
    <button mat-icon-button class="rpc-icon-btn" [color]="color" [rpcBadge]="badgeCount"
        [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit"
        [animationConfig]="animationConfig" (click)="clicked.emit($event)" title="{{ title | translate }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
            enableCustomBackground ? 'rpc-icon-btn--custom' : '',
            badgeIcon ? 'rpc-icon-btn--badge-icon' : '',
            hasTrigger && appearanceType === 'icon' ? 'rpc-icon-btn--trigger' : ''
        ]" [disabled]="disabled" [type]="type">

        <rpc-icon *ngIf="iconName != null && iconName !== ''" [iconName]="iconName" class="rpc-icon-btn-icon">
        </rpc-icon>

        <div *ngIf="badgeIcon" class="rpc-icon-btn-badge">

            <rpc-icon class="rpc-icon-btn-badge-icon" [iconName]="badgeIconName">
            </rpc-icon>

        </div>

        <rpc-icon *ngIf="hasTrigger && appearanceType === 'icon'" [iconName]="'arrow-down'"
            class="rpc-icon-btn-trigger">
        </rpc-icon>

        <span class="rpc-icon-btn-bg" *ngIf="enableCustomBackground"></span>

    </button>
</ng-container>

<ng-container *ngIf="appearanceType === 'mini-fab'">
    <button mat-mini-fab class="rpc-mini-fab-btn" [color]="color" [rpcBadge]="badgeCount"
        [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit"
        (click)="clicked.emit($event)" title="{{ title | translate }}" [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : ''
            ]" [disabled]="disabled" [type]="type">

        <rpc-icon *ngIf="iconName != null && iconName !== ''" [iconName]="iconName" class="rpc-mini-fab-btn-icon">
        </rpc-icon>

    </button>
</ng-container>

<ng-container *ngIf="appearanceType === 'fab'">
    <button mat-fab class="rpc-fab-btn" [color]="color" [rpcBadge]="badgeCount" [rpcBadgeAlert]="badgeCountAlert"
        [rpcBadgeCountAlertLimit]="badgeCountAlertLimit" (click)="clicked.emit($event)" title="{{ title | translate }}"
        [ngClass]="[
            actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : ''
        ]" [disabled]="disabled" [type]="type">

        <rpc-icon *ngIf="iconName != null && iconName !== ''" [iconName]="iconName" class="rpc-fab-btn-icon">
        </rpc-icon>

    </button>
</ng-container>
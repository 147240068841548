import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store/src/models';
import { concat, of } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';

import * as restorePasswordActions from '@auth/store/actions/restore-password.actions';
import { ApiError } from '@error/models/api-error';
import { AppConfigurationService } from '@app-config/services/app-configuration.service';

@Injectable()
export class RestorePasswordEffects {

    constructor(
        @Inject('AUTH_API_URL') private readonly authApiUrl: { url: string },
        private readonly actions$: Actions,
        private readonly http: HttpClient,
        private readonly configurationService: AppConfigurationService
    ) { }

    public readonly sendPasswordRestoreLink$ = createEffect(() => this.actions$.pipe(
        ofType(restorePasswordActions.sendRestorePasswordLink),
        concatLatestFrom(() => this.configurationService.getCompanyConfiguration()),
        switchMap(([{ request: { email } }, company]) =>
            this.http.post(this.authApiUrl.url + '/api/auth/customers/send-restore-link', { email, companyId: company.internalId })
                .pipe(
                    map((response: ApiError): Action => {
                        return response != null && response.errorKey == null && response.errors == null
                            ? restorePasswordActions.sendRestorePasswordLinkSuccessful()
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            : restorePasswordActions.sendRestorePasswordLinkFailed({ ...response, errorKey: (response.errors[0] as any).code });
                    }),
                    catchError(
                        (errorResponse: HttpErrorResponse) => of(
                            restorePasswordActions.sendRestorePasswordLinkFailed(errorResponse.error as ApiError)))
                )),
        share()
    ));

    public readonly checkCode$ = createEffect(() => this.actions$.pipe(
        ofType(restorePasswordActions.checkCode),
        switchMap((action) =>
            this.http.post(this.authApiUrl.url + '/api/auth/customers/check-restore-code', action.request)
                .pipe(
                    map((response: ApiError): Action => {
                        return response != null && response.errorKey == null && response.errors == null
                            ? restorePasswordActions.checkCodeSuccessful()
                            : restorePasswordActions.checkCodeFailed(response);
                    }),
                    catchError((errorResponse: HttpErrorResponse) => of(restorePasswordActions.checkCodeFailed(errorResponse.error as ApiError)))
                )),
        share()
    ));

    public readonly changePassword$ = createEffect(() => this.actions$.pipe(
        ofType(restorePasswordActions.changePassword),
        switchMap((action) =>
            this.http.post(this.authApiUrl.url + '/api/auth/customers/change-password', action.request)
                .pipe(
                    map((): Action => {
                        return restorePasswordActions.changePasswordSuccessful();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => of(restorePasswordActions.changePasswordFailed(errorResponse.error as ApiError)))
                )),
        share()
    ));
}
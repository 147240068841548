import { createAction, props } from '@ngrx/store';

import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { SortOptionBase } from '@core-models/sort/sort-option';
import { ViewModes } from '@view-base/models/view-modes.enum';
import { CustomerSettings } from '@settings/models/settings/customer-settings';

export const loadSuccess = createAction(
    '[On Market Page] Load Success',
    props<{ searchOptions: ListingSearchOptions, previousSearchOptions: ListingSearchOptions }>()
);

export const setSort = createAction(
    '[Search Options] Set Sort',
    props<{ sort: SortOptionBase }>()
);

export const setViewMode = createAction(
    '[Search Options] Set View Mode',
    props<{ viewMode: ViewModes }>()
);

export const setMapSizePercent = createAction(
    '[Search Options] Set Map Size Percent',
    props<{ mapSizePercent: number }>()
);

export const resetSavedView = createAction(
    '[Search Options] Reset Saved View',
    props<CustomerSettings>()
);

export const setNeighborhoodLayerVisibility = createAction('[On Market Page] Set Neighborhood Layer Visibility', props<{ isVisible: boolean }>());

export const resetState = createAction('[On Market Page] Reset State');
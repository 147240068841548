/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ConnectionPositionPair, FlexibleConnectedPositionStrategy, Overlay, OverlayPositionBuilder, OverlayRef, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, Type } from '@angular/core';
import { DynamicComponentHostDirective } from '@core-directives/dynamic-component-host/dynamic-component-host';
import { Subject, takeUntil, } from 'rxjs';

import { Debounce } from '@core-decorators/debounce.decorator';
import { RpcTooltipComponent } from '../components/rpc-tooltip.component';

@Directive({
    selector: '[rpcTooltip]',
    exportAs: 'rpcTooltip'
})
export class RpcTooltipDirective implements OnInit, OnDestroy {

    @Input() public showToolTip = true;
    @Input('rpcTooltip') public text: string;
    @Input() public customClass: string | null;
    @Input() public contentTemplate: TemplateRef<HTMLElement>;
    @Input() public component: Type<unknown>;
    @Input() public componentScopeData: unknown;
    @Input() public trigger: 'hover' | 'singleClick' | 'longTap' = 'hover';
    @Input() public tooltipActiveClass = 'rpc-active';
    @Input() public shouldCloseOnTooltipClick = true;
    @Input() public shouldKeepOpenOnClick = false;

    @Output() public tooltipInitialized = new EventEmitter<ComponentRef<DynamicComponentHostDirective> | null>();

    public tooltipRef?: ComponentRef<RpcTooltipComponent>;

    private readonly positions = [
        new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
        new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' }),
        new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
        new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' })
    ];

    private readonly longTapTimeout = 500;

    private unsubscribe$ = new Subject<void>();
    private positionStrategy: FlexibleConnectedPositionStrategy;
    private scrollStrategy: ScrollStrategy;
    private overlayRef: OverlayRef;
    private isMouseInTooltip = false;
    private isOpen = false;
    private shouldNotClose = false;
    private isOutsideEventsCloseAllowed = true;
    private longTapTimer: NodeJS.Timeout | null = null;

    private tooltipEnterListenerStopAction: () => void;
    private tooltipLeaveListenerStopAction: () => void;
    private clickOutsideListener: (e: Event) => void;

    constructor(
        private readonly overlay: Overlay,
        private readonly overlayPositionBuilder: OverlayPositionBuilder,
        private readonly elementRef: ElementRef,
        private readonly renderer2: Renderer2,
        private readonly scrollStrategyOptions: ScrollStrategyOptions
    ) { }

    public ngOnInit(): void {
        this.positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions(this.positions)
            .withFlexibleDimensions(false)
            .withPush(false);
        this.scrollStrategy = this.scrollStrategyOptions.close();
    }

    public ngOnDestroy(): void {
        this.removeClickOutsideListener();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.closeTooltip();
        this.scrollStrategy?.disable();
    }

    @HostListener('mouseenter')
    public onHover(): void {
        if (this.trigger !== 'hover') {
            return;
        }

        this.show();
    }

    @Debounce(30)
    @HostListener('mousedown')
    public onLongTap(): void {
        if (this.trigger === 'singleClick') {
            this.show();
        }
    }

    @Debounce(30)
    @HostListener('touchstart')
    public onSingleClick(): void {
        if (this.trigger === 'longTap' && this.longTapTimer == null) {
            this.longTapTimer = setTimeout(() => {
                this.tryClearLongTapedTimer();
                this.show();
            }, this.longTapTimeout);
        }

        if (this.shouldKeepOpenOnClick) {
            this.shouldNotClose = true;
        }
    }

    @Debounce(100)
    @HostListener('mouseleave')
    public onHoverEnd(): void {
        if (this.trigger !== 'hover' || this.shouldNotClose) {
            return;
        }

        this.tryCloseTooltip();
    }

    @Debounce(100)
    @HostListener('mouseup')
    @HostListener('touchend')
    @HostListener('touchcancel')
    public onLongTapEnd(): void {
        this.tryClearLongTapedTimer();
    }

    public setOutsideEventsCloseAllowance(isAllowed: boolean): void {
        this.isOutsideEventsCloseAllowed = isAllowed;
    }

    public tryCloseTooltip(force = false): void {
        if (!this.showToolTip) {
            return;
        }

        if (force) {
            this.isMouseInTooltip = false;
        }

        if (!this.isMouseInTooltip) {
            this.closeTooltip();
        }
    }

    private show(): void {
        if (!this.showToolTip) {
            return;
        }

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$ = new Subject<void>();

        if (this.overlayRef != null) {
            this.closeTooltip();
        }

        this.overlayRef = this.overlay.create({ positionStrategy: this.positionStrategy, scrollStrategy: this.scrollStrategy });

        if (this.overlayRef != null && !this.overlayRef.hasAttached()) {
            this.addClickOutsideListener();
            this.tooltipRef = this.overlayRef.attach(new ComponentPortal(RpcTooltipComponent));
            this.tooltipRef.instance.text = this.text ?? '';
            this.tooltipRef.instance.customClass = this.customClass ?? '';
            this.tooltipRef.instance.contentTemplate = this.contentTemplate;
            this.tooltipRef.instance.component = this.component;
            this.tooltipRef.instance.componentScopeData = this.componentScopeData;
            this.tooltipRef.instance.initialized
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(component => this.onComponentInitialized(component));

            this.renderer2.setStyle(this.overlayRef.overlayElement, 'visibility', 'hidden');
            this.renderer2.setStyle(this.overlayRef.overlayElement, 'oppacity', 0);

            this.tooltipLeaveListenerStopAction = this.renderer2
                .listen(this.overlayRef.overlayElement, 'mouseleave', () => {
                    if (!this.isOutsideEventsCloseAllowed) {
                        return;
                    }

                    this.isMouseInTooltip = false;

                    if (!this.shouldNotClose) {
                        this.closeTooltip();
                    }
                });

            this.tooltipEnterListenerStopAction = this.renderer2
                .listen(this.overlayRef.overlayElement, 'mouseenter', () => {
                    this.isMouseInTooltip = true;
                });

            this.isOpen = true;
        }
    }

    private tryClearLongTapedTimer(): void {
        if (this.trigger === 'longTap' && this.longTapTimer != null) {
            clearTimeout(this.longTapTimer);
            this.longTapTimer = null;
        }
    }

    private closeTooltip(): void {
        if (this.overlayRef != null) {
            this.overlayRef.detach();
            this.overlayRef.dispose();
            this.overlayRef = null;
        }

        if (this.tooltipEnterListenerStopAction != null) {
            this.tooltipEnterListenerStopAction();
        }

        if (this.tooltipLeaveListenerStopAction != null) {
            this.tooltipLeaveListenerStopAction();
        }

        this.isOpen = false;
        this.shouldNotClose = false;
        this.renderer2.removeClass(this.elementRef.nativeElement, this.tooltipActiveClass);
    }

    private onComponentInitialized(component: ComponentRef<DynamicComponentHostDirective> | null): void {
        setTimeout(() => {
            if (this.overlayRef?.overlayElement?.style != null) {
                this.overlayRef.updatePosition();
                this.renderer2.setStyle(this.overlayRef.overlayElement, 'visibility', null);
                this.renderer2.setStyle(this.overlayRef.overlayElement, 'oppacity', 1);
                this.renderer2.addClass(this.elementRef.nativeElement, this.tooltipActiveClass);
            }
        });

        this.tooltipInitialized.emit(component);
    }

    private addClickOutsideListener(): void {
        this.clickOutsideListener ??= e => {
            if (!this.isOpen || this.tooltipRef == null) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            const isClickedInside = this.elementRef.nativeElement.contains(e.target) || this.tooltipRef.instance.elementRef.nativeElement.contains(e.target);

            if (!isClickedInside && this.isOutsideEventsCloseAllowed) {
                e.stopPropagation();
                this.closeTooltip();

                return;
            }

            if (isClickedInside && this.shouldCloseOnTooltipClick && !this.shouldNotClose) {
                setTimeout(() => this.closeTooltip());
            }

        };

        document.addEventListener('click', this.clickOutsideListener, { capture: true });
    }

    private removeClickOutsideListener(): void {
        document.removeEventListener('click', this.clickOutsideListener);
    }
}
import { createReducer, on } from '@ngrx/store';

import { SearchState } from '../states/search.state';
import * as patternSearchActions from '../actions/pattern-search.actions';
import * as searchCriteriaActions from '../actions/search-criteria.actions';
import * as neighborhoodActions from '../actions/neighborhood.actions';

export const searchReducer = createReducer(
    new SearchState(),
    on(patternSearchActions.searchByPatternSuccess, (state, { patternSearchResults }) => ({ ...state, patternSearchResults })),
    on(searchCriteriaActions.setSearchOptions, (state, { searchOptions }) => ({ ...state, searchOptions })),
    on(searchCriteriaActions.setSearchRunMode, (state, { mode }) => ({ ...state, searchRunMode: mode })),
    on(neighborhoodActions.loadNeighborhoodPropertiesSuccess, (state, action) => ({ ...state, neighborhoodProperties: action.neighborhoodProperties })),
);
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import isEqual from 'lodash-es/isEqual';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ToastService } from '@core-services/toast.service';
import { LISTING_ERRORS_MAP } from '@listings/constants/listing-errors.constants';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingApiService } from '@listings/store/services/listing-api.service';
import * as settingsActions from '@settings/store/actions/settings.actions';
import * as listingActivityActions from '../actions/listing-activity.actions';
import { ListingsStoreService } from '../services/listings-store.service';
import { loadListingsPriceChanges } from '../actions/listing-price-changes.actions';
import { LISTING_NOT_FOUND_ERROR_KEY } from '@listings/constants/listings.constants';

@Injectable()
export class ListingsEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly listingApiService: ListingApiService,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly toaster: ToastService,
        private readonly routeService: RouteService
    ) { }

    public readonly loadListing$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listingActions.loadListing),
            switchMap(({ id }) => this.listingApiService.loadListing(id)),
        )
    );

    public readonly loadListingFailed$ = createEffect(
        () => this.actions$.pipe(
            ofType(listingActions.loadListingFailed),
            tap(({ errorKey }) => {
                if (errorKey === LISTING_NOT_FOUND_ERROR_KEY) {
                    this.routeService.navigate(RpcRoute.FindHome).catch(() => { });
                }
            })),
        { dispatch: false }
    );

    public readonly loadListingSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.loadListingSuccess),
        map(({ listing }) => loadListingsPriceChanges({ hashCodes: [listing.hashCode] }))
    ));

    public readonly loadloadListingsDetails$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.loadListingsDetails),
        concatLatestFrom(() => this.listingsStoreService.listingsDetails$),
        switchMap(([{ hashCodes }, listingsDetails]) => {
            const listingsToLoadDetails = hashCodes.filter(hashCode => hashCode != null && listingsDetails[hashCode] == null);

            return listingsToLoadDetails.length > 0 ? this.listingApiService.loadListingsDetails(listingsToLoadDetails) : EMPTY;
        }),
    ));

    public readonly loadListinsgByHashCodes$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.loadListinsgByHashCodes),
        mergeMap(({ hashCodes }) => this.listingApiService.loadListingsByHashCodes(hashCodes))
    ));

    public readonly loadCustomerListings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listingActions.loadCustomerListings),
            switchMap(() => this.listingApiService.loadCustomerListings()),
        )
    );

    public readonly loadListingsMedia$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listingActions.loadListingsMedia),
            concatLatestFrom(() => this.listingsStoreService.getAllListingsMedia()),
            distinctUntilChanged(isEqual),
            mergeMap(([{ listingIds }, medias]) => {
                const mediaToFetch = listingIds.filter(o => medias[o] == null);

                if (mediaToFetch.length === 0) {
                    return EMPTY;
                }

                return this.listingApiService.loadListingsMedia(mediaToFetch);
            }),
        )
    );

    public readonly loadMarketListings$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.loadMarketListings),
        switchMap(({ searchOptions, previousSearchOptions }) => this.listingApiService.loadMarketListings(searchOptions, previousSearchOptions)),
    ));

    public readonly markAsViewed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(listingActions.markAsViewed),
            concatLatestFrom(({ listingId }) => this.listingsStoreService.getListing(listingId)),
            switchMap(([{ listingId, listingHashCode }, listing]) => {
                return this.listingApiService.markAsViewed(listingId, listingHashCode, listing.pinOwnerAgentId);
            })
        )
    );

    public readonly showListingError$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                listingActions.softDeleteFailed,
                listingActions.hardDeleteFailed,
                listingActivityActions.setListingsActivityFailed,
                listingActions.restoreFailed
            ),
            tap(({ error }) => {
                const localizationKey = LISTING_ERRORS_MAP.get(error.errorKey);

                if (localizationKey != null) {
                    this.toaster.showClientError(localizationKey);
                }
            })
        ),
        { dispatch: false }
    );

    public readonly loadMarketListingsFailed$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.loadMarketListingsFailed),
        map(() => {
            this.routeService.navigate(RpcRoute.Portfolio).catch(() => { });

            return settingsActions.loadSettings();
        })
    ));
}

export const locale = {
    lang: 'en',
    data: {
        FORGOT_PASSWORD: {
            VALIDATION_ERRORS: {
                EMAIL_REQUIRED: 'Email is required',
                EMAIL_VALID: 'Please enter a valid email address',
                EMAIL_DOES_NOT_EXIST: 'The account does not Exist.'
            },
            FIELDS: {
                EMAIL: 'Email'
            },
            MESSAGES: {
                LINK_SENT_SUCCESSFULLY: 'Check your email, shortly you will receive instructions to reset your password.'
            },
            RESET_EXPLANATION: 'You can always reset your password here.',
            TITLE: 'Forgot Password?',
            SEND_BUTTON: 'RECOVER PASSWORD',
            GO_BACK_TO_LOGIN: 'Go back to login',
            ERRORS: {
                SERVER: {
                    CUSTOMER_NOT_FOUND: 'Account with current email does not exist.',
                    CLIENT_DOMAIN_MISSING: 'Client domain is missing.',
                    USER_ACTIVITY_NOT_FOUND: 'User activity not found.'
                }
            }
        }
    }
};
import { FolderUrlName } from '@folders/enums/folder-url-name';
import { FolderDetails } from '@folders/models/folder-details';

export const SYSTEM_FOLDERS_INFO = {
    ALL_HOMES: { id: -1, name: 'ALL_HOMES_IN_PORTFOLIO' },
    // do not remove while we have these folders on the resource side
    // ExternalListings: { id: -2, name: 'EXTERNAL_LISTINGS' },
    // NotAssignedToFolder: { id: -3, name: 'NOT_ASSIGNED_TO_FOLDER' },
    // RemovedListings: { id: -4, name: 'REMOVED_LISTINGS' },
    // EmailedListings: { id: -5, name: 'EMAILED_LISTINGS' },
    ADDED_BY_AGENT: { id: -6, name: 'ADDED_BY_AGENT' },
    NEW_FROM_SAVED_SEARCHES: { id: -7, name: 'NEW_FROM_SAVED_SEARCHES' },
    OPEN_HOUSES: { id: -8, name: 'OPEN_HOUSES' },
};

export const SYSTEM_FOLDERS: FolderDetails[] = Object.values(SYSTEM_FOLDERS_INFO).map(({ id, name }): FolderDetails => {
    return {
        id,
        name,
        creatorInfo: null,
        createDateTime: null,
        isSystemFolder: true
    };
});

export const SYSTEM_FOLDERS_WITH_ACTIVITY_PANEL = [SYSTEM_FOLDERS_INFO.ALL_HOMES.id, SYSTEM_FOLDERS_INFO.OPEN_HOUSES.id];

export const SYSTEM_FOLDERS_WITHOUT_FILTERS = [SYSTEM_FOLDERS_INFO.ADDED_BY_AGENT.id, SYSTEM_FOLDERS_INFO.NEW_FROM_SAVED_SEARCHES.id];

export const MAX_IMAGES_ON_FOLDER_COVER = 5;

export const INITIAL_DEFAULT_FOLDER_ID = 0;

export const SYSTEM_FOLDERS_URL_NAME_TO_ID_MAP = new Map<FolderUrlName, number>([
    [FolderUrlName.AddedToPortfolio, SYSTEM_FOLDERS_INFO.ALL_HOMES.id],
    [FolderUrlName.AllHomes, SYSTEM_FOLDERS_INFO.ALL_HOMES.id],
    [FolderUrlName.AddedByAgent, SYSTEM_FOLDERS_INFO.ADDED_BY_AGENT.id],
    [FolderUrlName.NewFromSavedSearches, SYSTEM_FOLDERS_INFO.NEW_FROM_SAVED_SEARCHES.id],
    [FolderUrlName.OpenHouses, SYSTEM_FOLDERS_INFO.OPEN_HOUSES.id],
]);

export const SYSTEM_FOLDERS_ID_TO_URL_NAME_MAP = new Map<number, FolderUrlName>([
    [SYSTEM_FOLDERS_INFO.ALL_HOMES.id, FolderUrlName.AllHomes],
    [SYSTEM_FOLDERS_INFO.ADDED_BY_AGENT.id, FolderUrlName.AddedByAgent],
    [SYSTEM_FOLDERS_INFO.NEW_FROM_SAVED_SEARCHES.id, FolderUrlName.NewFromSavedSearches],
    [SYSTEM_FOLDERS_INFO.OPEN_HOUSES.id, FolderUrlName.OpenHouses],
]);
import { Component, OnInit } from '@angular/core';
import { switchMap, iif } from 'rxjs';

import { ProfileBaseStoreService } from '../../store/services/profile-base-store.service';
import { AdditionalProfileStoreService } from '@additional-profile/store/services/additional-profile-store.service';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { NewMatchSettingsModel } from '../../interfaces/new-match-settings/new-match-settings.model';

@Component({
    selector: 'new-match-settings-page',
    templateUrl: './new-match-settings-page.component.html',
    styleUrls: ['./new-match-settings-page.component.scss']
})
export class NewMatchSettingsPageComponent implements OnInit {

    public readonly newMatchSettings$ = this.profileBaseStoreService.isSelfProfile$.pipe(
        switchMap(isSelfProfile => iif(() => isSelfProfile, this.settingsStoreService.getNewMatchSettings(), this.additionalProfileStoreService.newMatchSettings$))
    );

    public isMinimizedToolbar = false;

    constructor(
        private readonly profileBaseStoreService: ProfileBaseStoreService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly additionalProfileStoreService: AdditionalProfileStoreService
    ) { }

    public ngOnInit() {
        this.profileBaseStoreService.loadNewMatchSettings();
    }

    public onNavigateBack(): void {
        this.profileBaseStoreService.navigateToProfile();
    }

    public onNewMatchSettingsChanged(settings: NewMatchSettingsModel): void {
        this.profileBaseStoreService.updateNewMatchSettings(settings);
    }

    public onToolbarMinimizerAnchorViewportChange(inViewport: boolean): void {
        this.isMinimizedToolbar = !inViewport;
    }
}

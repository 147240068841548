<div class="profile-toolbar" [class.profile-toolbar--minimized]="isMinimized">

    <rpc-icon-button class="profile-toolbar-back" [title]="'PROFILE_TOOLBAR.TITLES.BACK'" [iconName]="'left'"
        (clicked)="onNavigateBack()">
    </rpc-icon-button>

    <div class="profile-toolbar-title">
        {{ (isAdditionalProfile ? 'PROFILE_TOOLBAR.TITLES.CHILD_PROFILE' : 'PROFILE_TOOLBAR.TITLES.PROFILE') |
        translate}}
    </div>

    <rpc-icon-button class="profile-toolbar-btn" [iconName]="'edit'" (clicked)="onEdit()">
    </rpc-icon-button>

</div>
<div class="settings-profile-page">

    <div class="settings-profile-page-anchor" snInViewport
        (inViewportChange)="onToolbarMinimizerAnchorViewportChange($event)">
    </div>

    <div class="settings-profile-page-toolbar" [class.settings-profile-page-toolbar--minimized]="isMinimizedToolbar">

        <rpc-icon-button class="settings-profile-page-toolbar-back" [iconName]="'left'"
            [title]="'PROFILE_SETTINGS_PAGE.TITLES.BACK'" (clicked)="onNavigateBack()">
        </rpc-icon-button>

        <div class="settings-profile-page-toolbar-title" [title]="'PROFILE_SETTINGS_PAGE.TITLES.SETTINGS' | translate">
            {{ 'PROFILE_SETTINGS_PAGE.TITLES.SETTINGS' | translate }}
        </div>

    </div>

    <profile-settings [settings]="settings$ | async" [isSelfProfile]="isSelfProfile$ | async"
        class="settings-profile-page-body">
    </profile-settings>

    <rpc-scroll-to-top class="rpc-scroll-to-top-wrap">
    </rpc-scroll-to-top>

</div>
import { ServerMessagesMapping } from '@core-models/messages/server-messages-mapping';

export const serverMessagesMapping: ServerMessagesMapping = {
    Restore_Customer_Not_Found: {
        code: 400,
        messageTranslationPath: 'FORGOT_PASSWORD.ERRORS.SERVER.CUSTOMER_NOT_FOUND',
        title: ''
    },
    CUSTOMER_NOT_FOUND: {
        code: 400,
        messageTranslationPath: 'FORGOT_PASSWORD.ERRORS.SERVER.CUSTOMER_NOT_FOUND',
        title: ''
    },
    CLIENT_DOMAIN_MISSING: {
        code: 400,
        messageTranslationPath: 'FORGOT_PASSWORD.ERRORS.SERVER.CLIENT_DOMAIN_MISSING',
        title: ''
    },
    USER_ACTIVITY_NOT_FOUND: {
        code: 400,
        messageTranslationPath: 'FORGOT_PASSWORD.ERRORS.SERVER.USER_ACTIVITY_NOT_FOUND',
        title: ''
    },
};